import { IJoke } from "./Joke";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../rootReducer";
import { AppDispatch } from "../../store";
import Firebase, { IRawJoke } from "../../../api/Firebase";

const initialState: IJokeState = {
    jokes: [],
    isLoading: false,
    hasErrors: false
}

export const jokeSlice = createSlice({
    name: 'jokes',
    initialState,
    reducers: {
        setLoading: state => {
            state.isLoading = true;
        },
        fetchJokesSuccess: (state, { payload }: PayloadAction<IJoke[]>) => {
            state.jokes = payload;
            state.isLoading = false;
            state.hasErrors = false;
        },
        fetchJokesFailure: state => {
            state.isLoading = false;
            state.hasErrors = true;
        },
        addJokeSuccess: (state, { payload }: PayloadAction<IJoke>) => {
            state.jokes.unshift(payload);
            state.isLoading = false;
            state.hasErrors = false;
        },
        addJokeFailure: state => {
            state.isLoading = false;
            state.hasErrors = true;
        },
        deleteJokeSuccess: (state, {payload}: PayloadAction<string>) => {
            state.jokes = state.jokes.filter((joke: IJoke) => joke.id !== payload);
            state.isLoading = false;
            state.hasErrors = false;
        },
        deleteJokeFailure: state => {
            state.isLoading = false;
            state.hasErrors = true;
        },
        editJokeSuccess: (state, {payload}: PayloadAction<IJoke>) => {
            const index = state.jokes.findIndex((joke: IJoke) => joke.id === payload.id);
            if(index !== -1) {
                state.jokes[index].text = payload.text;
                state.isLoading = false;
                state.hasErrors = false;
            }
        },
        editJokeFailure: state => {
            state.isLoading = false;
            state.hasErrors = true;
        }
    }
});

export const fetchJokes = () => {
    return async (dispatch: AppDispatch) => {
        dispatch(setLoading());

        try {
            const jokes = await Firebase.getJokes();
            dispatch(fetchJokesSuccess(jokes));
        } catch (error) {
            dispatch(fetchJokesFailure());
        }
    }
};

export const addJoke = (jokeText: string, userId: string) => {
    return async (dispatch: AppDispatch) => {
        dispatch(setLoading());

        try {
            const raw: IRawJoke = {
                createdBy: userId,
                dateCreated: new Date().getTime(),
                joke: jokeText
            };
            const createdId = await Firebase.createJoke(raw);
            const createdJoke: IJoke = {
                text: jokeText,
                createdBy: userId,
                id: createdId!,
            }
            dispatch(addJokeSuccess(createdJoke));
        } catch (err) {
            dispatch(addJokeFailure());
        }
    };
}

export const deleteJoke = (id: string) => {
    return async (dispatch: AppDispatch) => {
        dispatch(setLoading());

        try {
            await Firebase.deleteJoke(id);
            dispatch(deleteJokeSuccess(id));
        } catch (err) {
            dispatch(deleteJokeFailure());
        }
    };
}

export const editJoke = (joke: IJoke) => {
    return async (dispatch: AppDispatch) => {
        dispatch(setLoading());

        try {
            await Firebase.editJoke(joke);
            dispatch(editJokeSuccess(joke));
        } catch (err) {
            dispatch(editJokeFailure());
        }
    }
}

export interface IJokeState {
    jokes: IJoke[];
    isLoading: boolean;
    hasErrors: boolean;
}

export const { setLoading,
    fetchJokesSuccess,
    fetchJokesFailure,
    addJokeSuccess,
    addJokeFailure,
    deleteJokeSuccess,
    deleteJokeFailure,
    editJokeSuccess,
    editJokeFailure } = jokeSlice.actions;

export const jokesSelector = (state: RootState) => state.jokes;
