import React, { useEffect } from 'react';
import { Switch, Route } from "react-router-dom";
import MainHeader from "./features/MainHeader";
import Login from './features/user/Login';
import SignUp from "./features/user/SignUp";
import { useSelector } from "react-redux";
import { fetchJokes, jokesSelector } from "./features/joke/jokeSlice";
import { useAppDispatch } from "./store";
import Joke from "./features/joke/Joke";
import { checkSessionStorage } from "./features/user/userSlice";
import AddJoke from "./features/joke/AddJoke";

const App = () => {
    const dispatch = useAppDispatch();
    const {jokes, isLoading, hasErrors} = useSelector(jokesSelector);

    useEffect(() => {
        dispatch(fetchJokes());
    }, [dispatch]);

    useEffect(() => {
        checkSessionStorage(dispatch);
    }, []);

    const renderJokes = () => {
        if(isLoading) return <p>Fetching jokes...</p>
        if(hasErrors) return <p>Error fetching jokes.</p>

        return jokes.map(joke => <Joke key={joke.id} text={joke.text} id={joke.id} createdBy={joke.createdBy} /> );
    }

    return (
        <div className="App">
            <MainHeader/>
            <main>
                <Switch>
                    <Route exact path="/">
                        <div className="joke-list">
                            {renderJokes()}
                        </div>
                    </Route>
                    <Route exact path="/login">
                        <Login />
                    </Route>
                    <Route exact path="/signup">
                        <SignUp />
                    </Route>
                    <Route exact path="/addJoke">
                        <AddJoke />
                    </Route>
                </Switch>
            </main>
        </div>
    );
}

export default App;
