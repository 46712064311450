import React, { useState } from "react";
import { ReactComponent as Vader } from "../../../assets/darth-vader-icon.svg";
import { useSelector } from "react-redux";
import { userSelector } from "../user/userSlice";
import { useAppDispatch } from "../../store";
import { deleteJoke, editJoke } from "./jokeSlice";
import TextareaAutosize from "react-textarea-autosize";

const Joke: React.FC<IJoke> = ({text, id,createdBy}) => {
    const {userId} = useSelector(userSelector);
    const [content, setContent] = useState(text);
    const [isEditing, setIsEditing] = useState(false);
    const dispatch = useAppDispatch();

    const removeJoke = () => {
        dispatch(deleteJoke(id));
    };

    const cancelEdit = () => {
        setContent(text);
        setIsEditing(false);
    };

    const modifyJoke = () => {
        const edited: IJoke = {
            id: id,
            createdBy: userId,
            text: content
        }
        dispatch(editJoke(edited)).then(() => {
            setIsEditing(false);
        }).catch(err => console.log(err));
    }

    const renderEditPanel = () => {
        if(userId === createdBy) {
            if(isEditing) {
                return (
                    <div className="joke__edit-panel">
                        <button onClick={() => cancelEdit()}>Cancel</button>
                        <button onClick={() => modifyJoke()}>Save</button>
                    </div>
                );
            }

            return (
                <div className="joke__edit-panel">
                    <button onClick={() => setIsEditing(true)}>Edit</button>
                    <button onClick={() => removeJoke()}>Delete</button>
                </div>
            );
        }

        return null;
    }

    return (
        <div className="joke">
            <Vader />
            <div className="joke__text-container">
                <TextareaAutosize
                    value={content}
                    onChange={e => setContent(e.target.value)}
                    readOnly={!isEditing}
                />
                {renderEditPanel()}
            </div>

        </div>
    );
};

export default Joke;

export interface IJoke {
    text: string;
    id: string;
    createdBy: string;
}
