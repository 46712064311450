import React, { FormEvent, useEffect, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { useAppDispatch } from "../../store";
import { addJoke, jokesSelector } from "./jokeSlice";
import { useSelector } from "react-redux";
import { userSelector } from "../user/userSlice";
import { useHistory } from "react-router-dom";

const AddJoke: React.FC = () => {
    const [joke, setJoke] = useState('');
    const {userId} = useSelector(userSelector);
    const dispatch = useAppDispatch();
    const history = useHistory();

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        dispatch(addJoke(joke, userId))
        .then(() => {
            history.push('/')
        }).catch((err: string) => {
            console.log(err);
        });
    };

    return (
        <form className="add-joke-form" onSubmit={handleSubmit}>
            <label htmlFor="joke">Tell your joke here:</label>
            <TextareaAutosize
                name="joke"
                value={joke}
                onChange={e => {setJoke(e.target.value)}}
                required
            />
            <div className="add-joke-form__buttons buttonWrapper">
                <button type="submit">Save</button>
            </div>
        </form>
    );
};

export default AddJoke;
