import React from 'react';
import { ReactComponent as LoginIcon } from "../../assets/login.svg";
import { ReactComponent as SignUpIcon } from "../../assets/account-plus.svg";
import { ReactComponent as LogoutIcon } from "../../assets/logout.svg";
import { ReactComponent as AddJokeIcon } from "../../assets/plus-box-outline.svg";
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from "react-redux";
import { logout, userSelector } from "./user/userSlice";
import { useAppDispatch } from "../store";

const MainHeader: React.FC = () => {
    const {userId} = useSelector(userSelector);
    const dispatch = useAppDispatch();
    const history = useHistory();
    const handleLogout = () => {
        dispatch(logout()).then(() => {
            history.push('/');
        });
    };

    const renderLinks = () => {
        if(userId !== '') {
            return (
                <>
                    <a onClick={() => handleLogout()} className="main-header__links__logout"><LogoutIcon/>Logout</a>
                    <Link to="/addJoke" className="main-header__links__add-joke"><AddJokeIcon/>Add Joke</Link>
                </>
            );
        }

        return (
            <>
                <Link to="/login" className="main-header__links__login"><LoginIcon />Login</Link>
                <Link to="/signup" className="main-header__links__sign-up"><SignUpIcon />Sign Up</Link>
            </>
        );
    };
    return (
        <header className="main-header">
            <Link to="/"><h1>Dad-A-Base</h1></Link>
            <div className="main-header__links">
                {renderLinks()}
            </div>
        </header>
    );
};


export default MainHeader;
