import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import rootReducer, { RootState } from "./rootReducer";
import { useDispatch } from "react-redux";

const store = configureStore({
    reducer: rootReducer
});


export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export type AppThunk = ThunkAction<any, RootState, unknown, Action>

export default store;
