import React, { FormEvent, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAppDispatch } from "../../store";
import { login, userSelector } from "./userSlice";
import { useSelector } from "react-redux";
import AuthErrorPopup from "./AuthErrorPopup";

const Login: React.FC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const dispatch = useAppDispatch();
    const history = useHistory();
    const {errors, userId} = useSelector(userSelector);

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const loginData: ILoginData = {
            email: email,
            password: password
        }
        dispatch(login(loginData));
    }

    useEffect(() => {
        if(userId !== '' && errors === undefined) {
            history.push('/');
        }
    }, [userId, errors]);

    const renderErrorPopup = () => {
        if(errors !== undefined) {
            return <AuthErrorPopup title="Error Logging In"
                                   content="Check your credentials and try again"/>
        }

        return null;
    }

    return (
        <section className="login-form">
            <form onSubmit={handleSubmit}>
                <label htmlFor="email">Email Address:</label>
                <input type="email"
                       required
                       name="email"
                       value={email}
                       onChange={e => {setEmail(e.target.value)}}
                       placeholder="Email Address"
                />

                <label htmlFor="password">Password:</label>
                <input type="password"
                       name="password"
                       required
                       value={password}
                       onChange={e => {setPassword(e.target.value)}}
                       placeholder="Password"
                />

                <div className="login-form__buttons buttonWrapper">
                    <button type="submit">Login</button>
                </div>
            </form>

            <p>Don't have an account? <Link to="/signup">Sign Up!</Link></p>

            {renderErrorPopup()}
        </section>
    );
};

export default Login;

export interface ILoginData {
    email: string;
    password: string;
}
