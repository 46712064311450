import React from "react";

const AuthErrorPopup: React.FC<IAuthError> = ({title, content}) => {
    return (
        <div className="auth-error-popup">
            <h2>{title}</h2>
            <p>{content}</p>
        </div>
    );
};

export default AuthErrorPopup;

export interface IAuthError {
    title: string;
    content: string;
}
