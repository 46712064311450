import React, { FormEvent, useEffect, useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import { ILoginData } from "./Login";
import { useAppDispatch } from "../../store";
import { signUp, userSelector } from "./userSlice";
import { useSelector } from "react-redux";
import AuthErrorPopup from "./AuthErrorPopup";

const SignUp: React.FC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirm, setConfirm] = useState('');
    const dispatch = useAppDispatch();
    const history = useHistory();
    const {userId, errors} = useSelector(userSelector);

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        const data: ISignUpData = {
            email: email,
            password: password,
            confirm: confirm
        }

        dispatch(signUp(data));

    }

    useEffect(() => {
        if(userId !== '' && errors === undefined) {
            history.push('/');
        }
    }, [userId, errors]);

    const renderErrorPopup = () => {
        if(errors !== undefined) {
            return <AuthErrorPopup title="Error Signing Up"
                                   content={errors.message} />
        }

        return null;
    }

    return (
        <section className="sign-up-form">
            <form onSubmit={handleSubmit}>
                <label htmlFor="email">Email Address:</label>
                <input type="email"
                       required
                       name="email"
                       value={email}
                       onChange={e => {setEmail(e.target.value)}}
                       placeholder="Email Address"
                />

                <label htmlFor="password">Password:</label>
                <input type="password"
                       name="password"
                       required
                       value={password}
                       onChange={e => setPassword(e.target.value)}
                       placeholder="Password"
                       minLength={6}
                />

                <label htmlFor="confirm">Confirm Password:</label>
                <input type="password"
                       name="confirm"
                       required
                       value={confirm}
                       onChange={e => setConfirm(e.target.value)}
                       placeholder="Confirm Password"
                       minLength={6}
                />

                <div className="sign-up-form__buttons buttonWrapper">
                    <button type="submit">Sign Up</button>
                </div>
            </form>

            <p>Already have an account? <Link to="/login">Login!</Link></p>

            {renderErrorPopup()}
        </section>
    );
};

export default SignUp;

export interface ISignUpData extends ILoginData {
    confirm: string;
}
