import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import {IJoke} from "../app/features/joke/Joke";

const config = {
    apiKey: 'AIzaSyAekpSSAJLdeYeynhvMcFKXYYvAEOvDSBE',
    authDomain: 'dad-a-base-3.firebaseapp.com',
    databaseURL: 'https://dad-a-base-3.firebaseio.com',
    projectId: 'dad-a-base-3',
    storageBucket: 'dad-a-base-3.appspot.com',
    messagingSenderId: '679660816580',
    appId: '1:679660816580:web:d6d287bdb803127fb72191',
    measurementId: 'G-MHJFN86LCQ'
}

export default {
    app: firebase.initializeApp(config),
    SESSION: firebase.auth.Auth.Persistence.SESSION,
    async login (email: string, password: string) {
        const firebase = this.app
        await firebase.auth().setPersistence(this.SESSION)
        return await firebase.auth()
            .signInWithEmailAndPassword(email, password)
    },
    async logout () {
        const firebase = this.app;
        return await firebase.auth().signOut();
    },
    async signUp (email: string, password: string) {
        const firebase = this.app;
        return await firebase.auth().createUserWithEmailAndPassword(email, password);
    },
    async getJokes () {
        const firebase = this.app;
        const j = await firebase.database().ref('/jokes').orderByChild('createdDate').once('value');
        const jokes = j.toJSON();

        if(jokes !== null) {
            const jokeList: IJoke[] = [];
            for(const [id, joke] of Object.entries(jokes)) {
                const converted = joke as IRawJoke;
                const formatted: IJoke = {
                    id: id,
                    text: converted.joke,
                    createdBy: converted.createdBy
                };
                jokeList.push(formatted);
            }
            return jokeList.reverse();
        }

        return [];
    },
    async createJoke (joke: IRawJoke) {
        const firebase = this.app
        return firebase.database().ref('/jokes').push(joke).key;
    },
    async deleteJoke (id: string) {
        const firebase = this.app;
        return firebase.database().ref('/jokes').child(id).remove();
    },
    async editJoke (joke: IJoke) {
        const firebase = this.app;
        const converted: IRawJoke = {
            joke: joke.text,
            createdBy: joke.createdBy,
            dateCreated: new Date().getTime()
        }
        return firebase.database().ref('/jokes').child(joke.id).set(converted);
    }
}

export interface IRawJoke {
    createdBy: string;
    dateCreated: number;
    joke: string;
}
