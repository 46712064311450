import { combineReducers } from "@reduxjs/toolkit";
import { jokeSlice } from "./features/joke/jokeSlice";
import { userSlice } from "./features/user/userSlice";

const rootReducer = combineReducers({
    jokes: jokeSlice.reducer,
    user: userSlice.reducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
